import React from 'react'
import Layout from '../components/Layout'
import Link from "gatsby-link";
import Button from "react-bootstrap/Button";
import Grid from "@material-ui/core/Grid";

import bg from '../../static/img/lawyers.png'


const lawyer = () => {
    return (
        <Layout>
            <div
                className="full-width-image margin-top-0"
                style={{
                    marginTop: '100vh',

                    backgroundImage: `url(${
                        bg
                        })`,

                }}
            >
                <Grid item xs={12} sm={6}
                    style={{

                        padding: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        transform: 'scale(0.6)',


                    }}
                >
                    <div style={{


                        display: 'flex',
                        padding: '0.5em',
                        marginTop: '60px',

                        paddingBottom: '20px',
                        paddingTop: '20px',


                        flexDirection: 'column',

                    }}>
                        <h2
                            className="is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
                            style={{

                                // color: '#152189',
                                color: 'white',
                                padding: '1rem',
                                position: 'static',
                                lineHeight: '1.5',
                                fontSize: '3rem',
                                fontWeight: '700',



                            }}
                        >
                            Uncapped earnings, working when you want and where you want.
        </h2>

                        <p style={{
                            color: 'white',
                            fontSize: '1.75rem',
                            lineHeight: '1.5',
                            padding: '1em',
                        }}>Join MyTribe and become a member of a team of expert estate planning lawyers. Whether you are currently employed, on leave or between jobs, join us to experience unparallel flexibility and fantastic remuneration.  </p>

                        <Button variant="primary" style={{
                            margin: '35px',
                            backgroundColor: '#48D1CC',
                            border: 'none',
                            width: '40%',
                            borderRadius: '10px',
                            fontSize: '30px',
                            padding: '15px',

                        }}>
                            <a href="https://app.my-tribe.com.au/lawyer-signup" target='_blank' style={{
                                color: '#ffffff',
                                borderRadius: '10px',

                            }}>
                                Join Today
                  </a>
                        </Button>



                    </div>
                </Grid>
            </div>

            <section className="section" style={{ marginTop: '0.5rem', marginBottom: '0.8rem', }}>
                <h1 style={{ fontSize: '2rem', fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '55px' }}>Why join MyTribe</h1>
                {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', }}> */}
                <Grid container spacing={12}>
                    <Grid item xs={12} sm={4} >

                        <Grid item xs={12} sm={12} style={{ marginLeft: '1em', marginRight: '1em' }} >
                            <div style={{
                                display: 'flex', justifyContent: 'center', flexDirection: 'column',
                                alignItems: 'center'
                            }}>

                                <h1 style={{ fontWeight: '600', fontSize: '1.5rem' }}>Earn great money</h1>
                                {/* <img style={{ position: 'center', height: '180px', marginTop: '15px', marginBottom: '15px' }} src='https://firebasestorage.googleapis.com/v0/b/wills-platform.appspot.com/o/public%2Fimage%2Fearn-money-min.jpg?alt=media&token=bb77d3ca-e395-433d-9965-c34693aec9c2' alt="register"></img> */}

                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{ height: '180px' }} viewBox="5 115 150 100"  >

                                    <g xmlns="http://www.w3.org/2000/svg">
                                        <path fill="#7598D9" d="M93.615,191.492l-6.589,3.076l-10.75,4.936l-19.657-1.001l2.849-5.122l17.167-0.97    c6.767-1.393,11.675-4.824,14.194-4.84C93.156,187.559,94.082,189.581,93.615,191.492z" />
                                        <path fill="#7598D9" d="M89.004,157.795l-6.177-13.301l-14.303,5.744l5.815,2.208C61,174.028,35.002,181.646,35.002,181.646    s30-2.367,48.37-25.929L89.004,157.795z" />
                                        <path fill="#85ABEF" d="M82.827,144.494c-14.303,5.744-14.303,5.744-14.303,5.744l5.815,2.208    C61,174.028,35.002,181.646,35.002,181.646S76.202,172.362,82.827,144.494z" />
                                        <path fill="#1D3285" d="M95.005,144.494L95.005,144.494c-2.21,0-4.001,1.791-4.001,4l0.001,42.087h8v-42.087    C99.005,146.285,97.214,144.494,95.005,144.494z" />
                                        <path fill="#3353B6" d="M95.005,155.494L95.005,155.494c-2.21,0-4.001,1.791-4.001,4l0.001,31.087h8v-31.087    C99.005,157.285,97.214,155.494,95.005,155.494z" />
                                        <path fill="#1D3285" d="M85.004,159.496L85.004,159.496c-2.21,0-4.001,1.79-4.001,4v30.997c0,2.209,1.791,4,4,4h0.001    c2.209,0,4-1.791,4-4v-30.997C89.004,161.286,87.213,159.496,85.004,159.496z" />
                                        <path fill="#3353B6" d="M85.004,167.495L85.004,167.495c-2.21,0-4.001,1.791-4.001,4v23c0,2.209,1.791,4,4,4h0.001    c2.209,0,4-1.791,4-4v-23C89.004,169.286,87.213,167.495,85.004,167.495z" />
                                        <path fill="#1D3285" d="M75.003,168.497L75.003,168.497c-2.21,0-4,1.791-4,4v23.996c0,2.209,1.791,4,4,4h0c2.209,0,4-1.791,4-4    v-23.996C79.003,170.288,77.212,168.497,75.003,168.497z" />
                                        <path fill="#3353B6" d="M75.003,174.497L75.003,174.497c-2.21,0-4,1.791-4,4v18c0,2.209,1.791,4,4,4h0c2.209,0,4-1.791,4-4v-18    C79.003,176.288,77.212,174.497,75.003,174.497z" />
                                        <path fill="#1D3285" d="M65.002,174.497L65.002,174.497c-2.21,0-4.001,1.791-4.001,4v17.996c0,2.209,1.791,4,4,4h0.001    c2.209,0,4-1.791,4-4v-17.996C69.002,176.288,67.211,174.497,65.002,174.497z" />
                                        <path fill="#3353B6" d="M65.002,178.495L65.002,178.495c-2.21,0-4.001,1.791-4.001,4v14c0,2.209,1.791,4,4,4h0.001    c2.209,0,4-1.791,4-4v-14C69.002,180.286,67.211,178.495,65.002,178.495z" />
                                        <path fill="#1D3285" d="M55.001,178.497L55.001,178.497c-2.21,0-4,1.791-4,4v13.996c0,2.209,1.791,4,4,4h0c2.209,0,4-1.791,4-4    v-13.996C59.001,180.288,57.21,178.497,55.001,178.497z" />
                                        <path fill="#3353B6" d="M55.001,181.497L55.001,181.497c-2.21,0-4,1.791-4,4v11c0,2.209,1.791,4,4,4h0c2.209,0,4-1.791,4-4v-11    C59.001,183.288,57.21,181.497,55.001,181.497z" />
                                        <circle fill="#DF751B" cx="48" cy="157.493" r="13" />
                                        <circle fill="#E39C26" cx="48" cy="157.493" r="10.5" />
                                        <path fill="#EBF3FD" d="M48.013,165.06c-1.898,0-3.569-1.057-4.257-2.694c-0.193-0.458,0.022-0.985,0.481-1.179    c0.458-0.191,0.986,0.023,1.178,0.481c0.4,0.952,1.444,1.593,2.598,1.593c1.526,0,2.769-1.092,2.769-2.434    c0-1.11-0.483-2.434-2.782-2.434c-3.368,0-4.568-2.187-4.568-4.232c0-2.334,2.049-4.233,4.568-4.233    c1.898,0,3.569,1.058,4.258,2.694c0.193,0.458-0.022,0.985-0.48,1.178c-0.46,0.193-0.985-0.021-1.179-0.48    c-0.4-0.951-1.445-1.592-2.599-1.592c-1.526,0-2.768,1.092-2.768,2.434c0,1.592,0.957,2.433,2.768,2.433    c3.378,0,4.582,2.187,4.582,4.233C52.581,163.161,50.532,165.06,48.013,165.06z" />
                                        <path fill="#EBF3FD" d="M48,151.728c-0.497,0-0.9-0.402-0.9-0.9v-1.834c0-0.496,0.403-0.899,0.9-0.899s0.9,0.403,0.9,0.899v1.834    C48.899,151.325,48.497,151.728,48,151.728z" />
                                        <path fill="#EBF3FD" d="M48,166.894c-0.497,0-0.9-0.402-0.9-0.9v-1.833c0-0.497,0.403-0.899,0.9-0.899s0.9,0.402,0.9,0.899v1.833    C48.899,166.491,48.497,166.894,48,166.894z" />
                                        <circle fill="#EBF3FD" cx="55.001" cy="157.493" r="1" />
                                        <circle fill="#EBF3FD" cx="41" cy="157.493" r="1" />
                                        <path fill="#85ABEF" d="M79.038,194.657c-1.15,0.458-3.042-1.136-6.292-0.729c-0.001,0-15.146-3.167-27.383-3.385    c-0.016,0,0,9.899,0,9.899c3.805,1.467,23.335,14.449,52.049-7.627c0.03-0.022,0.061-0.045,0.094-0.063    c0.218-0.138,2.141-1.405,1.272-2.975C97.127,186.796,87.538,191.268,79.038,194.657z" />
                                        <path fill="rgb(72, 209, 204)" d="M83.031,196.401c-1.099,0.57-2.237,1.16-3.41,1.755c-0.001,0.001-0.003,0.001-0.004,0.001    c-0.005,0.003-0.008,0.008-0.014,0.011c-2.816,1.361-5.818,2.039-9.155,2.039c-2.576,0-5.352-0.405-8.395-1.208    c-0.58-0.154-0.927-0.75-0.773-1.33c0.153-0.581,0.752-0.924,1.33-0.773c6.441,1.705,11.535,1.487,16.022-0.676h0.001    c1.981-1.018,2.027-1.391,2.117-2.134c0.148-1.229-0.047-2.144-0.581-2.716c-0.796-0.854-2.18-0.795-2.197-0.797    c-10.435,0.182-12.342-0.938-14.542-2.229c-1.99-1.167-6.829-2.585-19.066-2.803c-0.016,0,0,17.9,0,17.9    c3.805,1.467,23.204,14.277,52.049-7.627c0.03-0.021,2.234-1.469,1.366-3.038C96.127,189.796,91.1,192.214,83.031,196.401z" />
                                        <path fill="#3353B6" d="M44.002,184.646h-8c-0.553,0-1,0.447-1,1v18c0,0.553,0.447,1,1,1h8c0.552,0,1-0.447,1-1v-18    C45.002,185.093,44.554,184.646,44.002,184.646z" />
                                        <circle fill="#EBF3FD" cx="40.002" cy="200.146" r="1.5" />
                                        <circle fill="#B1CDFC" cx="65.91" cy="156.994" r="1.5" />
                                        <circle fill="#B1CDFC" cx="36.502" cy="170.493" r="1.5" />
                                        <circle fill="#B1CDFC" cx="47" cy="173.16" r="1" />
                                        <circle fill="#B1CDFC" cx="41.292" cy="176.618" r="1" />
                                        <circle fill="#B1CDFC" cx="37" cy="146.493" r="1" />
                                        <circle fill="#B1CDFC" cx="62.723" cy="151.911" r="1" />
                                        <circle fill="#B1CDFC" cx="67" cy="146.494" r="2" />
                                    </g></svg>

                                {/* #85ABEF */}




                                <ul className='a' style={{ fontSize: '1.25rem', width: '80%', marginTop: '25px' }}><li >Up to 70% of the fees for each package
                                </li><br />
                                    <li >Full fee is payable within 48 hours of the appointment, so your earnings are secure despite late cancellations</li><br />
                                    <li>Get paid monthly </li><br /><br /></ul>
                            </div>

                        </Grid>

                    </Grid>

                    <Grid item xs={12} sm={4} >

                        <Grid item xs={12} sm={12} style={{ marginLeft: '1em', marginRight: '1em' }}>
                            <div style={{
                                display: 'flex', justifyContent: 'center', flexDirection: 'column',
                                alignItems: 'center'
                            }}>
                                <h1 style={{ fontWeight: '600', fontSize: '1.5rem' }}>Perks
</h1>
                                {/* <img style={{ position: 'center', height: '180px', marginTop: '15px', marginBottom: '15px' }} src='https://firebasestorage.googleapis.com/v0/b/wills-platform.appspot.com/o/public%2Fimage%2Fbenefit-min.jpg?alt=media&token=72fda167-3da4-463d-bb7a-68fd54e090a5' alt="register"></img> */}
                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{ height: '180px' }} viewBox="80 360 200 100">



                                    <g xmlns="http://www.w3.org/2000/svg">
                                        <circle fill="rgb(72, 209, 204)" cx="197.08" cy="406.161" r="21" />
                                        <path fill="#1D3285" d="M203.081,391.162c4.264,0,8.225,1.279,11.537,3.462c-3.756-5.697-10.204-9.463-17.538-9.463    c-11.598,0-21,9.402-21,21c0,7.335,3.766,13.782,9.463,17.538c-2.184-3.313-3.462-7.273-3.462-11.537    C182.081,400.563,191.483,391.162,203.081,391.162z" />
                                        <path fill="#DF751B" d="M197.08,427.161c5.93,0,11.275-2.467,15.094-6.42v-4.084c0-2.111-1.423-3.956-3.464-4.492l-5.378-1.413    h-6.251h-6.248l-5.38,1.413c-2.042,0.536-3.465,2.381-3.465,4.492v4.084C185.805,424.694,191.15,427.161,197.08,427.161z" />
                                        <path fill="#E6C1BD" d="M197.08,414.617c0,0,6.21-1.339,6.251-3.864c-1.316-0.675-2.304-1.904-2.582-3.383    c-0.041-0.217-0.072-1.591-0.096-1.799l-7.153,0.005c-0.028,0.238-0.065,1.648-0.115,1.904c-0.282,1.45-1.255,2.634-2.554,3.272    C190.887,413.269,197.08,414.617,197.08,414.617z" />
                                        <path fill="#E39C26" d="M188.024,418.166c-0.278,0-0.503,0.226-0.503,0.504v6.174c0.331,0.17,0.666,0.333,1.006,0.485v-6.659    C188.528,418.392,188.303,418.166,188.024,418.166z" />
                                        <path fill="#E39C26" d="M206.139,418.166c-0.278,0-0.503,0.226-0.503,0.504v6.657c0.341-0.152,0.676-0.314,1.006-0.484v-6.173    C206.642,418.392,206.417,418.166,206.139,418.166z" />
                                        <ellipse fill="#F3D3CF" cx="197.08" cy="400.854" rx="6.37" ry="7.985" />
                                        <path fill="#85ABEF" d="M190.808,399.713c0,0-1.558-1.995-0.572-5.777c1.019-3.911,4.005-3.187,4.005-3.187    s0.969-1.027,3.242-1.027c2.813,0,5.245,0.67,6.357,3.38c1.113,2.71-0.381,6.611-0.381,6.611s-0.954-3.425-4.068-4.438    c0,0-1.208,1.341-4.863,1.341C190.871,396.616,190.808,399.713,190.808,399.713z" />
                                        <path fill="#85ABEF" d="M203.079,439.142h-4c-0.552,0-1,0.447-1,1v9h5V439.142z" />
                                        <path fill="#B1CDFC" d="M198.078,444.142h-4c-0.552,0-1,0.448-1,1v3c0,0.553,0.448,1,1,1h4V444.142z" />
                                        <path fill="#7598D9" d="M208.079,434.142h-4c-0.552,0-1,0.447-1,1v14h5V434.142z" />
                                        <path fill="#3353B6" d="M213.08,429.142h-4c-0.553,0-1,0.447-1,1v19h5V429.142z" />
                                        <path fill="#1D3285" d="M217.08,424.141h-3c-0.552,0-1,0.448-1,1v24.001h4c0.553,0,1-0.448,1-1v-23.001    C218.08,424.589,217.633,424.141,217.08,424.141z" />
                                        <circle fill="#E39C26" cx="175.08" cy="428.161" r="21" />
                                        <path fill="#DF751B" d="M184.636,409.478c3.298,3.709,5.318,8.579,5.318,13.934c0,11.598-9.402,21-21,21    c-3.445,0-6.687-0.846-9.556-2.316c3.847,4.326,9.438,7.066,15.681,7.066c11.598,0,21-9.401,21-21    C196.08,420.009,191.425,412.957,184.636,409.478z" />
                                        <path fill="#EBF3FD" d="M154.08,428.161c0,5.799,2.351,11.049,6.151,14.85l5.471-5.471c-2.4-2.4-3.884-5.716-3.884-9.379    c0-3.662,1.484-6.979,3.884-9.378l-5.471-5.471C156.431,417.112,154.08,422.362,154.08,428.161z" />
                                        <path fill="#B1CDFC" d="M157.081,423.16c0-2.892,0.585-5.646,1.643-8.152c-2.899,3.601-4.643,8.171-4.643,13.153    c0,5.799,2.351,11.049,6.151,14.85l4.048-4.049C159.876,435.112,157.081,429.469,157.081,423.16z" />
                                        <path fill="#85ABEF" d="M175.08,407.161c-5.799,0-11.049,2.351-14.849,6.151l5.471,5.471c2.4-2.4,5.716-3.885,9.378-3.885    c3.663,0,6.979,1.484,9.379,3.885l5.471-5.471C186.129,409.512,180.879,407.161,175.08,407.161z" />
                                        <path fill="#7598D9" d="M180.081,410.162c2.892,0,5.646,0.585,8.154,1.643c-3.601-2.899-8.171-4.644-13.155-4.644    c-5.799,0-11.049,2.351-14.849,6.151l4.048,4.048C168.128,412.957,173.772,410.162,180.081,410.162z" />
                                        <path fill="#3353B6" d="M184.459,418.783c2.4,2.399,3.885,5.716,3.885,9.378c0,3.663-2.128,7.66-3.868,9.396l5.454,5.454    c3.8-3.801,6.15-9.051,6.15-14.85s-2.35-11.049-6.15-14.849L184.459,418.783z" />
                                        <path fill="#1D3285" d="M194.08,433.162c0,1.824-0.257,3.584-0.694,5.271c1.709-3.038,2.694-6.538,2.694-10.272    c0-5.799-2.35-11.049-6.15-14.849l-3.58,3.579C191.066,420.742,194.08,426.599,194.08,433.162z" />
                                        <path fill="#EBF3FD" d="M174.087,431.211c2.804,0.745,17.741,1.28,17.741,1.28s-13.201-6.936-16.039-7.689    c-1.77-0.47-3.586,0.584-4.056,2.354C171.263,428.926,172.317,430.741,174.087,431.211z" />
                                        <circle fill="#3353B6" cx="175.08" cy="428.161" r="1.5" />
                                        <path fill="#3353B6" d="M158.077,386.911h-2.996c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h2.996    c0.414,0,0.75,0.336,0.75,0.75S158.491,386.911,158.077,386.911z" />
                                        <path fill="#85ABEF" d="M184.079,386.911h-21.998c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h21.998    c0.414,0,0.75,0.336,0.75,0.75S184.493,386.911,184.079,386.911z" />
                                        <path fill="#3353B6" d="M158.077,391.411h-2.996c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h2.996    c0.414,0,0.75,0.336,0.75,0.75S158.491,391.411,158.077,391.411z" />
                                        <path fill="#85ABEF" d="M179.079,391.411h-16.998c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h16.998    c0.414,0,0.75,0.336,0.75,0.75S179.493,391.411,179.079,391.411z" />
                                        <path fill="#85ABEF" d="M169.078,395.911h-13.997c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h13.997    c0.414,0,0.75,0.336,0.75,0.75S169.492,395.911,169.078,395.911z" />
                                        <path fill="#3353B6" d="M158.077,400.411h-2.996c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h2.996    c0.414,0,0.75,0.336,0.75,0.75S158.491,400.411,158.077,400.411z" />
                                        <path fill="#85ABEF" d="M174.078,400.411h-11.997c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h11.997    c0.414,0,0.75,0.336,0.75,0.75S174.492,400.411,174.078,400.411z" />
                                        <path fill="#85ABEF" d="M173.078,404.911h-17.998c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h17.998    c0.414,0,0.75,0.336,0.75,0.75S173.492,404.911,173.078,404.911z" />
                                        <path fill="#85ABEF" d="M161.077,409.411h-5.996c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h5.996    c0.414,0,0.75,0.336,0.75,0.75S161.491,409.411,161.077,409.411z" />
                                    </g></svg>






                                <ul className='a' style={{ fontSize: '1.25rem', width: '80%', marginTop: '25px' }}><li>Insurance</li><br />
                                    <li >Great online resources and precedence
</li><br />
                                    <li>Support from an experienced team
</li><br />
                                    <li >Build your own client base
</li><br /><br /></ul>

                            </div>


                        </Grid>




                    </Grid>

                    <Grid item xs={12} sm={4} >

                        <Grid item xs={12} sm={12} style={{ marginLeft: '1em', marginRight: '1em' }} >
                            <div style={{
                                display: 'flex', justifyContent: 'center', flexDirection: 'column',
                                alignItems: 'center'
                            }}>
                                <h1 style={{ fontWeight: '600', fontSize: '1.5rem' }}>What you will need</h1>
                                {/* <img style={{ position: 'center', height: '180px', marginTop: '15px', marginBottom: '15px' }} src='https://firebasestorage.googleapis.com/v0/b/wills-platform.appspot.com/o/public%2Fimage%2Flaw-min.jpg?alt=media&token=ee32db8b-c97e-46d1-a89b-5274dd3f75f9' alt="register"></img> */}

                                <svg version="1.1" id="layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="30 240 90 100"
                                    style={{ height: '180px' }}>

                                    {/*  #1D3285*/}

                                    <g xmlns="http://www.w3.org/2000/svg">
                                        <path fill="rgb(72, 209, 204)" d="M102.875,294.829h-58c-1.657,0-3-1.344-3-3v-26c0-1.657,1.343-3,3-3h58c1.657,0,3,1.343,3,3v26    C105.875,293.485,104.532,294.829,102.875,294.829z" />
                                        <path fill="#3353B6" d="M102.875,292.829h-58c-1.657,0-3-1.344-3-3v-24c0-1.657,1.343-3,3-3h58c1.657,0,3,1.343,3,3v24    C105.875,291.485,104.532,292.829,102.875,292.829z" />
                                        <path fill="#B1CDFC" d="M46.879,274.334c-0.4,0-0.79-0.16-1.07-0.439c-0.27-0.28-0.43-0.671-0.43-1.061c0-0.4,0.16-0.78,0.43-1.06    c0.56-0.561,1.57-0.561,2.13-0.011c0.28,0.29,0.44,0.67,0.44,1.07c0,0.39-0.16,0.78-0.44,1.061    C47.66,274.174,47.27,274.334,46.879,274.334z" />
                                        <path fill="#B1CDFC" d="M94.47,272.834c0-0.83,0.67-1.5,1.5-1.5l0,0c0.82,0,1.5,0.67,1.5,1.5l0,0c0,0.82-0.68,1.5-1.5,1.5l0,0    C95.14,274.334,94.47,273.654,94.47,272.834z M89.56,272.834c0-0.83,0.67-1.5,1.5-1.5l0,0c0.83,0,1.5,0.67,1.5,1.5l0,0    c0,0.82-0.67,1.5-1.5,1.5l0,0C90.229,274.334,89.56,273.654,89.56,272.834z M84.649,272.834c0-0.83,0.67-1.5,1.5-1.5l0,0    c0.82,0,1.5,0.67,1.5,1.5l0,0c0,0.82-0.68,1.5-1.5,1.5l0,0C85.319,274.334,84.649,273.654,84.649,272.834z M79.739,272.834    c0-0.83,0.67-1.5,1.5-1.5l0,0c0.83,0,1.5,0.67,1.5,1.5l0,0c0,0.82-0.67,1.5-1.5,1.5l0,0    C80.41,274.334,79.739,273.654,79.739,272.834z M74.83,272.834c0-0.83,0.67-1.5,1.5-1.5l0,0c0.83,0,1.5,0.67,1.5,1.5l0,0    c0,0.82-0.67,1.5-1.5,1.5l0,0C75.5,274.334,74.83,273.654,74.83,272.834z M69.919,272.834c0-0.83,0.67-1.5,1.5-1.5l0,0    c0.83,0,1.5,0.67,1.5,1.5l0,0c0,0.82-0.67,1.5-1.5,1.5l0,0C70.589,274.334,69.919,273.654,69.919,272.834z M65.009,272.834    c0-0.83,0.67-1.5,1.5-1.5l0,0c0.83,0,1.5,0.67,1.5,1.5l0,0c0,0.82-0.67,1.5-1.5,1.5l0,0    C65.68,274.334,65.009,273.654,65.009,272.834z M60.1,272.834c0-0.83,0.67-1.5,1.5-1.5l0,0c0.83,0,1.5,0.67,1.5,1.5l0,0    c0,0.82-0.67,1.5-1.5,1.5l0,0C60.77,274.334,60.1,273.654,60.1,272.834z M55.189,272.834c0-0.83,0.67-1.5,1.5-1.5l0,0    c0.83,0,1.5,0.67,1.5,1.5l0,0c0,0.82-0.67,1.5-1.5,1.5l0,0C55.859,274.334,55.189,273.654,55.189,272.834z M50.279,272.834    c0-0.83,0.68-1.5,1.51-1.5l0,0c0.82,0,1.5,0.67,1.5,1.5l0,0c0,0.82-0.68,1.5-1.5,1.5l0,0    C50.959,274.334,50.279,273.654,50.279,272.834z" />
                                        <path fill="#B1CDFC" d="M100.87,274.334c-0.39,0-0.78-0.16-1.06-0.439c-0.27-0.28-0.43-0.671-0.43-1.061    c0-0.4,0.16-0.79,0.43-1.07c0.56-0.55,1.57-0.55,2.13,0c0.28,0.29,0.43,0.67,0.43,1.07c0,0.39-0.16,0.78-0.43,1.05    C101.66,274.174,101.27,274.334,100.87,274.334z" />
                                        <path fill="#B1CDFC" d="M46.879,269.334c-0.4,0-0.79-0.16-1.07-0.439c-0.27-0.28-0.43-0.671-0.43-1.061c0-0.4,0.16-0.79,0.43-1.07    c0.56-0.55,1.57-0.55,2.13,0c0.28,0.29,0.43,0.67,0.43,1.07c0,0.39-0.15,0.78-0.43,1.061    C47.66,269.174,47.27,269.334,46.879,269.334z" />
                                        <path fill="#B1CDFC" d="M93.979,267.834c0-0.83,0.67-1.5,1.49-1.5l0,0c0.83,0,1.51,0.67,1.51,1.5l0,0c0,0.82-0.68,1.5-1.51,1.5    l0,0C94.649,269.334,93.979,268.654,93.979,267.834z M88.58,267.834c0-0.83,0.67-1.5,1.49-1.5l0,0c0.83,0,1.51,0.67,1.51,1.5l0,0    c0,0.82-0.68,1.5-1.51,1.5l0,0C89.25,269.334,88.58,268.654,88.58,267.834z M83.18,267.834c0-0.83,0.67-1.5,1.5-1.5l0,0    c0.82,0,1.5,0.67,1.5,1.5l0,0c0,0.82-0.68,1.5-1.5,1.5l0,0C83.85,269.334,83.18,268.654,83.18,267.834z M77.779,267.834    c0-0.83,0.67-1.5,1.49-1.5l0,0c0.83,0,1.51,0.67,1.51,1.5l0,0c0,0.82-0.68,1.5-1.51,1.5l0,0    C78.45,269.334,77.779,268.654,77.779,267.834z M72.37,267.834c0-0.83,0.68-1.5,1.51-1.5l0,0c0.82,0,1.49,0.67,1.49,1.5l0,0    c0,0.82-0.67,1.5-1.49,1.5l0,0C73.049,269.334,72.37,268.654,72.37,267.834z M66.97,267.834c0-0.83,0.68-1.5,1.51-1.5l0,0    c0.82,0,1.49,0.67,1.49,1.5l0,0c0,0.82-0.67,1.5-1.49,1.5l0,0C67.649,269.334,66.97,268.654,66.97,267.834z M61.569,267.834    c0-0.83,0.68-1.5,1.51-1.5l0,0c0.82,0,1.49,0.67,1.49,1.5l0,0c0,0.82-0.67,1.5-1.49,1.5l0,0    C62.25,269.334,61.569,268.654,61.569,267.834z M56.18,267.834c0-0.83,0.67-1.5,1.5-1.5l0,0c0.82,0,1.5,0.67,1.5,1.5l0,0    c0,0.82-0.68,1.5-1.5,1.5l0,0C56.85,269.334,56.18,268.654,56.18,267.834z M50.779,267.834c0-0.83,0.67-1.5,1.5-1.5l0,0    c0.82,0,1.5,0.67,1.5,1.5l0,0c0,0.82-0.68,1.5-1.5,1.5l0,0C51.45,269.334,50.779,268.654,50.779,267.834z" />
                                        <path fill="#B1CDFC" d="M100.879,269.334c-0.4,0-0.79-0.16-1.07-0.439c-0.27-0.28-0.44-0.671-0.44-1.061    c0-0.4,0.17-0.79,0.44-1.07c0.56-0.55,1.57-0.55,2.13,0c0.28,0.28,0.44,0.67,0.44,1.07c0,0.39-0.16,0.78-0.44,1.061    C101.649,269.174,101.27,269.334,100.879,269.334z" />
                                        <path fill="#B1CDFC" d="M46.879,279.334c-0.4,0-0.79-0.16-1.07-0.439c-0.27-0.28-0.44-0.671-0.44-1.061c0-0.4,0.17-0.78,0.45-1.06    c0.55-0.561,1.56-0.561,2.12,0c0.27,0.279,0.43,0.659,0.43,1.06c0,0.39-0.16,0.78-0.43,1.061    C47.66,279.174,47.27,279.334,46.879,279.334z" />
                                        <path fill="#B1CDFC" d="M93.97,277.834c0-0.83,0.68-1.5,1.51-1.5l0,0c0.82,0,1.49,0.67,1.49,1.5l0,0c0,0.82-0.67,1.5-1.49,1.5l0,0    C94.649,279.334,93.97,278.654,93.97,277.834z M88.569,277.834c0-0.83,0.68-1.5,1.51-1.5l0,0c0.82,0,1.5,0.67,1.5,1.5l0,0    c0,0.82-0.68,1.5-1.5,1.5l0,0C89.25,279.334,88.569,278.654,88.569,277.834z M83.18,277.834c0-0.83,0.67-1.5,1.49-1.5l0,0    c0.83,0,1.51,0.67,1.51,1.5l0,0c0,0.82-0.68,1.5-1.51,1.5l0,0C83.85,279.334,83.18,278.654,83.18,277.834z M77.779,277.834    c0-0.83,0.67-1.5,1.49-1.5l0,0c0.83,0,1.5,0.67,1.5,1.5l0,0c0,0.82-0.67,1.5-1.5,1.5l0,0    C78.45,279.334,77.779,278.654,77.779,277.834z M72.37,277.834c0-0.83,0.68-1.5,1.51-1.5l0,0c0.82,0,1.49,0.67,1.49,1.5l0,0    c0,0.82-0.67,1.5-1.49,1.5l0,0C73.049,279.334,72.37,278.654,72.37,277.834z M66.979,277.834c0-0.83,0.67-1.5,1.49-1.5l0,0    c0.83,0,1.51,0.67,1.51,1.5l0,0c0,0.82-0.68,1.5-1.51,1.5l0,0C67.649,279.334,66.979,278.654,66.979,277.834z M61.58,277.834    c0-0.83,0.67-1.5,1.49-1.5l0,0c0.83,0,1.51,0.67,1.51,1.5l0,0c0,0.82-0.68,1.5-1.51,1.5l0,0    C62.25,279.334,61.58,278.654,61.58,277.834z M56.18,277.834c0-0.83,0.67-1.5,1.5-1.5l0,0c0.82,0,1.49,0.67,1.49,1.5l0,0    c0,0.82-0.67,1.5-1.49,1.5l0,0C56.85,279.334,56.18,278.654,56.18,277.834z M50.779,277.834c0-0.83,0.67-1.5,1.5-1.5l0,0    c0.82,0,1.5,0.67,1.5,1.5l0,0c0,0.82-0.68,1.5-1.5,1.5l0,0C51.45,279.334,50.779,278.654,50.779,277.834z" />
                                        <path fill="#B1CDFC" d="M100.879,279.334c-0.4,0-0.79-0.16-1.07-0.439c-0.28-0.29-0.44-0.671-0.44-1.061    c0-0.4,0.16-0.78,0.44-1.06c0.56-0.561,1.57-0.561,2.13,0c0.28,0.279,0.43,0.659,0.43,1.06c0,0.39-0.15,0.771-0.43,1.061    C101.66,279.174,101.27,279.334,100.879,279.334z" />
                                        <path fill="#B1CDFC" d="M46.879,284.334c-0.4,0-0.79-0.16-1.07-0.439c-0.28-0.29-0.44-0.671-0.44-1.061c0-0.4,0.16-0.78,0.44-1.06    c0.57-0.561,1.57-0.561,2.13-0.011c0.28,0.29,0.44,0.67,0.44,1.07c0,0.39-0.16,0.78-0.44,1.061    C47.66,284.174,47.27,284.334,46.879,284.334z" />
                                        <path fill="#B1CDFC" d="M94.47,282.834c0-0.83,0.67-1.5,1.5-1.5l0,0c0.82,0,1.5,0.67,1.5,1.5l0,0c0,0.82-0.68,1.5-1.5,1.5l0,0    C95.14,284.334,94.47,283.654,94.47,282.834z M89.56,282.834c0-0.83,0.67-1.5,1.5-1.5l0,0c0.83,0,1.5,0.67,1.5,1.5l0,0    c0,0.82-0.67,1.5-1.5,1.5l0,0C90.229,284.334,89.56,283.654,89.56,282.834z M84.649,282.834c0-0.83,0.67-1.5,1.5-1.5l0,0    c0.83,0,1.5,0.67,1.5,1.5l0,0c0,0.82-0.67,1.5-1.5,1.5l0,0C85.319,284.334,84.649,283.654,84.649,282.834z M79.739,282.834    c0-0.83,0.67-1.5,1.5-1.5l0,0c0.83,0,1.5,0.67,1.5,1.5l0,0c0,0.82-0.67,1.5-1.5,1.5l0,0    C80.41,284.334,79.739,283.654,79.739,282.834z M74.83,282.834c0-0.83,0.67-1.5,1.5-1.5l0,0c0.83,0,1.5,0.67,1.5,1.5l0,0    c0,0.82-0.67,1.5-1.5,1.5l0,0C75.5,284.334,74.83,283.654,74.83,282.834z M69.919,282.834c0-0.83,0.67-1.5,1.5-1.5l0,0    c0.83,0,1.5,0.67,1.5,1.5l0,0c0,0.82-0.67,1.5-1.5,1.5l0,0C70.589,284.334,69.919,283.654,69.919,282.834z M65.009,282.834    c0-0.83,0.67-1.5,1.5-1.5l0,0c0.83,0,1.5,0.67,1.5,1.5l0,0c0,0.82-0.67,1.5-1.5,1.5l0,0    C65.68,284.334,65.009,283.654,65.009,282.834z M60.1,282.834c0-0.83,0.67-1.5,1.5-1.5l0,0c0.83,0,1.5,0.67,1.5,1.5l0,0    c0,0.82-0.67,1.5-1.5,1.5l0,0C60.77,284.334,60.1,283.654,60.1,282.834z M55.189,282.834c0-0.83,0.67-1.5,1.5-1.5l0,0    c0.83,0,1.5,0.67,1.5,1.5l0,0c0,0.82-0.67,1.5-1.5,1.5l0,0C55.859,284.334,55.189,283.654,55.189,282.834z M50.279,282.834    c0-0.83,0.68-1.5,1.5-1.5l0,0c0.83,0,1.5,0.67,1.5,1.5l0,0c0,0.82-0.67,1.5-1.5,1.5l0,0    C50.959,284.334,50.279,283.654,50.279,282.834z" />
                                        <path fill="#B1CDFC" d="M100.87,284.334c-0.39,0-0.78-0.16-1.06-0.439c-0.28-0.29-0.44-0.671-0.44-1.061    c0-0.4,0.16-0.78,0.44-1.06c0.57-0.561,1.56-0.561,2.12-0.011c0.28,0.28,0.44,0.67,0.44,1.07c0,0.39-0.16,0.78-0.43,1.061    C101.66,284.174,101.27,284.334,100.87,284.334z" />
                                        <path fill="#B1CDFC" d="M82.375,290.34h-17c-0.829,0-1.5-0.672-1.5-1.5s0.671-1.5,1.5-1.5h17c0.829,0,1.5,0.672,1.5,1.5    S83.204,290.34,82.375,290.34z" />
                                        <path fill="#E39C26" d="M46.667,291.828c0.529-0.025,1.027,0.157,1.408,0.519s0.59,0.851,0.589,1.375    c0,0.005,0.002,0.009,0.002,0.014v9.12c0,0.311,0.252,0.563,0.563,0.563s0.563-0.253,0.563-0.563v-9.133l0-0.001v-3.562    c0-1.067,0.804-1.978,1.793-2.028c0.526-0.024,1.027,0.157,1.408,0.519s0.59,0.85,0.59,1.374v12.063    c0,0.311,0.252,0.563,0.563,0.563s0.563-0.253,0.563-0.563l0-12.063l0-1.164c0-1.068,0.805-1.979,1.794-2.028    c0.526-0.023,1.025,0.157,1.405,0.519c0.38,0.361,0.59,0.85,0.59,1.374l0.001,3.68v0.001v0.001v8.915    c0,0.311,0.252,0.563,0.563,0.563s0.563-0.253,0.563-0.563v-8.917l0-0.001v-0.064c0.019-0.542,0.267-1.048,0.688-1.396    c0.445-0.366,1.008-0.507,1.587-0.396c0.863,0.166,1.514,1.005,1.514,1.952v11.64c0,0,0.001,0.004,0.001,0.006l0,4.949    c0,0.312,0.252,0.563,0.563,0.563s0.563-0.252,0.563-0.563v-6.558c0-0.768,0.335-1.497,0.897-1.95    c0.497-0.399,1.112-0.546,1.732-0.412c0.988,0.216,1.733,1.279,1.733,2.475V313.2c0,1.244-0.464,2.46-1.272,3.336l-2.799,2.834    c-0.915,0.927-1.418,2.153-1.418,3.455v1.779H48.29c-0.029-3.769-0.598-7.506-1.691-11.114c-1.143-3.771-1.723-7.683-1.723-11.623    l0-8.01C44.875,292.79,45.679,291.879,46.667,291.828z" />
                                        <path fill="#DF751B" d="M63.852,320.291H48.154c-0.276,0-0.5,0.224-0.5,0.5v5.539c0,0.276,0.224,0.5,0.5,0.5h15.698    c0.276,0,0.5-0.224,0.5-0.5v-5.539C64.352,320.515,64.128,320.291,63.852,320.291z" />
                                        <circle fill="#EBF3FD" cx="61.273" cy="323.561" r="1.078" />
                                        <path fill="#E39C26" d="M101.083,291.828c-0.529-0.025-1.027,0.157-1.408,0.519s-0.59,0.851-0.589,1.375    c0,0.005-0.002,0.009-0.002,0.014v9.12c0,0.311-0.252,0.563-0.563,0.563s-0.563-0.253-0.563-0.563v-9.133l0-0.001v-3.562    c0-1.067-0.804-1.978-1.793-2.028c-0.526-0.024-1.027,0.157-1.408,0.519s-0.589,0.85-0.589,1.374l0,12.063    c0,0.311-0.252,0.563-0.563,0.563s-0.563-0.253-0.563-0.563l0-12.063l0-1.164c0-1.068-0.805-1.979-1.794-2.028    c-0.526-0.023-1.025,0.157-1.405,0.519c-0.38,0.361-0.59,0.85-0.59,1.374l-0.001,3.68v0.001v0.001v8.915    c0,0.311-0.252,0.563-0.563,0.563s-0.563-0.253-0.563-0.563v-8.917l0-0.001v-0.064c-0.019-0.542-0.267-1.048-0.688-1.396    c-0.445-0.366-1.008-0.507-1.587-0.396c-0.863,0.166-1.515,1.005-1.515,1.952l0,11.64c0,0-0.001,0.004-0.001,0.006l0,4.949    c0,0.312-0.252,0.563-0.563,0.563s-0.563-0.252-0.563-0.563l0-6.558c0-0.768-0.335-1.497-0.897-1.95    c-0.497-0.399-1.112-0.546-1.732-0.412c-0.988,0.216-1.733,1.279-1.733,2.475V313.2c0,1.244,0.464,2.46,1.272,3.336l2.8,2.834    c0.915,0.927,1.418,2.153,1.418,3.455v1.779H99.46c0.029-3.769,0.598-7.506,1.691-11.114c1.143-3.771,1.723-7.683,1.723-11.623    l0-8.01C102.875,292.79,102.07,291.879,101.083,291.828z" />
                                        <path fill="#DF751B" d="M83.897,320.291h15.698c0.276,0,0.5,0.224,0.5,0.5v5.539c0,0.276-0.224,0.5-0.5,0.5H83.897    c-0.276,0-0.5-0.224-0.5-0.5v-5.539C83.397,320.515,83.622,320.291,83.897,320.291z" />
                                        <circle fill="#EBF3FD" cx="97.017" cy="323.561" r="1.078" />
                                        <polygon fill="rgb(72, 209, 204)" points="69.875,296.58 70.83,298.58 69.875,300.58 71.875,299.625 73.875,300.58 72.919,298.58     73.875,296.58 71.875,297.536   " />
                                        <polygon fill="rgb(72, 209, 204)" points="73.875,306.581 74.83,308.581 73.875,310.581 75.875,309.626 77.875,310.581 76.919,308.581     77.875,306.581 75.875,307.537   " />
                                        <polygon fill="rgb(72, 209, 204)" points="101.875,316.582 102.83,318.582 101.875,320.582 103.875,319.627 105.875,320.582     104.919,318.582 105.875,316.582 103.875,317.538   " />
                                        <polygon fill="rgb(72, 209, 204)" points="65.874,321.583 66.829,323.583 65.874,325.583 67.874,324.628 69.874,325.583 68.919,323.583     69.874,321.583 67.874,322.538   " />
                                    </g>





                                </svg>




                                <ul className='a' style={{ fontSize: '1.25rem', width: '80%', marginTop: '25px' }}><li>A car to get around and a Laptop</li><br />
                                    <li>At least 3 years’ experience in estate panning</li><br />
                                    <li>A current practicing certificate</li><br />
                                    <li>We provide the rest</li><br /><br /></ul>
                            </div>


                        </Grid>




                    </Grid>
                </Grid>


                {/* /</div> */}
            </section>






            <section className="section" style={{ marginTop: '0.5rem', marginBottom: '0.8rem', }}>


                <div style={{ display: 'flex' }}>
                    <Grid item xs={12} sm={4}>
                        <img style={{ marginLeft: '5vw' }} src='https://firebasestorage.googleapis.com/v0/b/wills-platform.appspot.com/o/public%2Fimage%2FCanva%20-%20Lawyer%20Signing%20Documents%20at%20His%20Desk%20in%20an%20Office-min-min.jpg?alt=media&token=271cef66-8d34-4a10-baaf-2bec4126c68a' alt="register" ></img>
                    </Grid>
                    <Grid item xs={12} sm={8} >
                        <div style={{ alignItems: 'left', marginLeft: '12vw', }}>
                            <div>
                                <h2 style={{ lineHeight: '1.5em', fontSize: '1.25rem', fontWeight: 'bold' }}>Choose when you work<br /><br /> </h2>
                                <h3 style={{ lineHeight: '1.5em', }}>Jobs will be offered to you, pick only the ones that suit your schedule and start earning today.  </h3>
                            </div>
                            <div style={{ marginTop: '1rem', }}>

                                <Button variant="primary" href='https://app.my-tribe.com.au/lawyer-signup' style={{

                                    // margin: '10px',

                                    textAlign: 'center',
                                    color: 'white',
                                    fontSize: '1em',
                                    borderColor: '#FFF',
                                    borderRadius: '10px',
                                    paddingLeft: '18px',
                                    paddingRight: '18px',
                                    paddingTop: '10px',
                                    paddingBottom: '10px',

                                    backgroundColor: '#48D1CC',
                                }}>
                                    Register

              </Button>
                            </div>
                        </div>
                    </Grid>
                </div>




            </section>

        </Layout >
    )
}

export default lawyer